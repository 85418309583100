.header {
    position:fixed;
    top:0;
    left:0;
    display:grid;
    justify-content: center;
    align-items: center;
    width:100%;
    height:140px;
    background-color: #1C2C6D;
    z-index:9999;
}

.header svg {
    width:200px;
}

.headerSubtitle{
    text-align: center;
    color:#fff;
    font-size:20px;
    font-weight: 600;
}

.headerArrow {
    font-size:36px;
    color:white;
}

.headerArrowContainer {
    color:white;
    position:absolute;
    left:0;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.ualterVersion {
    font-family: "Inter", sans-serif;
    font-size: 10px;
    position:absolute;
    bottom:2px;
    right:8px;
    color:white;
}

.ualterUser {
    font-family: "Inter", sans-serif;
    font-size: 10px;
    position:absolute;
    bottom:2px;
    left:8px;
    color:white;
}

.ualterUser a {
    font-family: "Inter", sans-serif;
    font-size: 10px;
    color:white;
    text-decoration: underline!important;
}