.HomeContent {
    display:grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top:200px;
  }

.HomeContainerImg {
    margin-top:20px;
    margin-bottom:50px;
}  
