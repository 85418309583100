/* Form.css */
form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: white;
    padding:15px !important;
    margin-top:15px !important;
    border-radius:6px;
}

form h3 {
    text-align: center;
    margin-bottom: 10px;
}

.formContainer {
    display:grid;
    justify-content: center;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    padding: 10px;
}

.loader {
    color: white;
}

.send-button {
    padding: 10px;
    font-size: 16px;
    background-color: #1c2c6d;
    color: white;
    border: none;
    cursor: pointer;
}

.row {
    display: flex;
    justify-content: space-between;
}

.date-picker {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

.upload-drag-icon {
    font-size: 24px;
    color: #1c2c6d;
}

.upload-text {
    color: #1c2c6d;
    font-weight: bold;
}