.save-button {
    background-color: #1c2c6d;
    color: white;
    padding: 14px 20px;
    margin-top: 8px;
    margin-right: 8px;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
}

.undo-button {
    background-color: #3753c5;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
}

.goback-button {
    background-color: #3753c5;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
    margin-left: 8px;

}

.label h4 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 32px;
    padding-top: 30px;
    margin-bottom: 40px;
}