

.segment_text, .segment_text_edited {
    padding-bottom:20px;
}

.segment_text_edited {
    color:gray;
}

.is_playing {
    background-color:#FFE484;
}

.CorregirTranscript:hover, .segment_text:hover {
    cursor: pointer;
}

.CorregirTranscript {
    display:flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom:10px;
    margin-top:7px;
}

.CorregirTranscript span {
    font-size:18px;
}

.btnLgg {
    
    display:flex;
    align-items: center;
}

.btnSegmentoCorregido {
    position:absolute;
    bottom:5px;
    right:10px;
    display:flex;
    align-items: center;
    justify-content: flex-end;
    font-size:19px;
}

.transcriptEditContainer textarea {
    padding:0;
    border:0 !important;
    margin:0;
    outline: none !important;
    resize:none;
    background-color: #ffffff80;
    border-color: white !important;
    max-height: 100%;
    outline-color: white !important;
    margin-bottom:15px;
    
}

.transcriptEditContainer textarea:focus {
    outline: none !important;
    border-color: white !important;
    outline-color: white !important;
    background-color: #ffffff80;
    box-shadow:none;
}

.transcriptEditContainer textarea:hover {
    background-color: inherit;
}

.transcriptEditContainer {
    background-color: #ffffff80;
    padding:20px;
    margin-top:10px;
    border:4px solid #ff5b5bb7;
    border-radius: 16px;

}

.transcriptEditBtns {
    max-width:100%;
    display:flex;
}

.transcriptSegmentContainer {
    display:grid;
    margin:0 auto;
    justify-content: center;
    grid-template-columns: 10% 60%;
}

.transcriptSegmentContainer, .transcriptEditContainer textarea {
    font-family: "Inter", sans-serif;
    word-spacing: 1px;
    font-size:19px;
    font-weight: 500;
}

.transcriptPlayContainer {
    display:flex;
    padding-top:4px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.btnTranscriptControl {
    font-size:16px;
}

.btnTranscriptControl:hover {
    cursor: pointer;
}

.noChanges {
    color:#ff5a43;
    text-decoration: underline;
}

.changes-confirm {
    border-radius: 16px;
    background-color: #333fc3;
    color:white;
    padding-left:35px;
    padding-right:35px;
    padding-top:10px;
    padding-bottom: 10px;
    font-weight: 600;
    margin-right:60px;
}

.transcriptEditBtns {
    display:flex;
    padding:20px;
    padding-top:0;
    justify-content: center;
    align-items: center;
}

.transcriptTextEdited {
    position: relative;
    padding:20px;
    margin-top:10px;
    border:4px solid #ff5b5bb7;
    border-radius: 16px;
}

.btnSegmentoCancelar {
    
    border-radius: 16px;
    background-color: #DD2A27;
    color:white;
    padding-left:35px;
    padding-right:35px;
    padding-top:10px;
    padding-bottom: 10px;
    font-weight: 600;
}