.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.listAudiosLinesHeader, .listAudiosLines {
    padding:10px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    place-content: center;
}

.listAudiosLines {
    font-size:20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.263);
}

.listAudiosLinesHeader {
    justify-content: center;
    font-size: x-large;
}

.listAudiosContainer h2 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size:32px;
    padding-top:30px;
    margin-bottom:40px;
}

.lggHover:hover {
    cursor: pointer;
}
