.button {
    color:white;
    font-size:25px;
    background-color: #1C2C6D;
    width:450px;
    border-radius: 12px;
    padding-top:8px;
    padding-bottom:8px;
    margin-bottom:15px;
    text-align: center;
}

.button:hover {
    cursor: pointer;
}