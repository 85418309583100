.edit-modules {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
}

.edit-modules h2 {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 32px;
    padding-top: 30px;
    margin-bottom: 40px;
}

.transcriptHeader {
    /*position: sticky;*/
    top: 140px;
    /*left: 0;*/
    background-color: #F5F5F5;
    z-index: 9999;
}

.transcriptHeaderTitle {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 32px;
    padding-top: 30px;
}

.transcriptFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.transcriptFooter p {
    font-size: 17px;
}

.transcriptFooterBtn {
    border-radius: 32px;
    background-color: #333fc3;
    color: white;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 35px;
    padding-bottom: 35px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 40px;
}
