@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
	--font-inter: 'Inter', sans-serif;
}

.auth-header {
	background-color: #1c2c6d;
	max-height: 100px;
}

.auth-header img {
	max-height: 90px;
	padding: 5px;
	margin: 0 auto;
	display: block;
}

.soundtxt {
	font-family: var(--font-inter);
	font-weight: 600;
}
