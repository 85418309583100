.action-icon {
    font-size: large;
    margin: 5px;
}

.edit-action-icon {
    color: darkblue;
}

.delete-action-icon {
    color: darkred;
}

.undo-action-icon {
    color: darkgreen;
}